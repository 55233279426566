import DeleteButton from '../buttons/DeleteButton';
import PropTypes from 'prop-types';
import React from 'react';
import Search from 'antd/lib/input/Search';
import { Button, Col, Row, Spin, Table } from 'antd';
import { ajax, createNotification, createNotificationShort, formatDate } from '../../helper';
import { confirmationModal } from '../modals/ConfirmationModal';
import { connect } from 'react-redux';
import { deleteDeputyData } from '../../actions/admin';
import { getTranslate } from 'react-localize-redux';
import { toggleModal } from '../../actions/modal';
import { ADMIN_EDIT_DEPUTY } from '../../constants/modalNames';


class DeputyList extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.state = {
            deputyList: [],
            filterString: '',
            loading: true,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        ajax()
            .get(`/admin/deputies`)
            .then((res) => {
                if (res.data) {
                    const deputyList = res.data.map((data, i) => ({ key: i, ...data }));
                    this._isMounted && this.setState({ deputyList, loading: false });
                }
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    filterDeputyList(deputyList, filterString) {
        if (filterString && filterString.length > 2) {
            const lowerCasedFilter = filterString.toLowerCase();
            return deputyList.filter((item) =>
                Object.keys(item).some((key) =>
                    key === 'key' ? false : item[key].toLowerCase().includes(lowerCasedFilter)
                )
            );
        } else {
            return deputyList;
        }
    }

    generateDeputyListElementKey(deputyList) {
        return (
            Math.max.apply(
                Math,
                deputyList.map((d) => d.key)
            ) + 1
        );
    }

    addElementToDeputyList = (deputy) => {
        let deputyList = [...this.state.deputyList];
        const newKey = this.generateDeputyListElementKey(deputyList);
        deputyList.push({
            key: newKey,
            userEmail: deputy.userEmail,
            deputyEmail: deputy.deputyEmail,
            from: deputy.deputyPeriod.from,
            to: deputy.deputyPeriod.to,
        });
        this._isMounted && this.setState({ deputyList });
    };

    updateElementInDeputyList = (deputy) => {
        let deputyList = [...this.state.deputyList];
        const targetElementIndex = deputyList.findIndex((element) => element.userEmail === deputy.userEmail);
        let updatedItem = { ...deputyList[targetElementIndex] };
        Object.keys(deputy).forEach((key) => {
            updatedItem[key] = deputy[key];
        });
        deputyList[targetElementIndex] = updatedItem;
        this._isMounted && this.setState({ deputyList });
    };

    onDeleteDeputy = async (deputyData) => {
        const { translate } = this.props;
        try {
            await this.props.deleteDeputy(deputyData);
            createNotificationShort(this.props.translate, {
                message: translate('messages.success.deleteDeputy'),
                type: 'success',
            });
            this._isMounted &&
                this.setState((prev) => ({
                    deputyList: prev.deputyList.filter((deputy) => deputy.userEmail !== deputyData.userEmail),
                }));
        } catch (e) {
            createNotification(this.props.translate, e.response.data.code, {
                description: translate(`errorCodes.${e.response.data.code}`),
                message: translate('messages.error.deleteDeputy'),
                type: 'error',
            });
        }
    };

    editDeputyData = (deputyData) => {
        this.props.openEditDeputyModal({
            deputy: deputyData,
            createMode: false,
            onFinish: this.updateElementInDeputyList,
        });
    };

    newDeputyData = () => {
        this.props.openEditDeputyModal({
            deputy: {},
            createMode: true,
            onFinish: this.addElementToDeputyList,
        });
    };

    onSearch = (text) => {
        this.setState({ filterString: text });
    };

    resetFilterIfSearchFieldIsEmpty = (event) => {
        if (event.target.value === '' && this.state.filterString !== '') {
            this.onSearch('');
        }
    };

    render() {
        const { deputyList, filterString, loading } = this.state;
        const { translate } = this.props;
        const filteredData = this.filterDeputyList(deputyList, filterString);
        const columns = getColumns(this.editDeputyData, this.onDeleteDeputy, translate);

        return (
            <div>
                <Row type="flex" align="middle" style={{ marginBottom: '1rem' }}>
                    <Col sm={10} md={10} lg={8}>
                        <Search
                            onSearch={this.onSearch}
                            onChange={this.resetFilterIfSearchFieldIsEmpty}
                            placeholder={this.props.translate('adminPage.common.emailSearchPlaceholder')}
                            allowClear
                        />
                    </Col>
                    <Col>
                        <Button style={{ margin: '1rem' }} icon="plus" onClick={this.newDeputyData}>
                            {translate('adminPage.deputy.newDeputy')}
                        </Button>
                    </Col>
                </Row>
                <Spin spinning={loading}>
                    <Table columns={columns} dataSource={filteredData} style={{ overflow: 'auto' }} locale={{emptyText: translate('noDeputy')}} />
                </Spin>
            </div>
        );
    }
}

DeputyList.propTypes = {
    history: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
    openEditDeputyModal: PropTypes.func.isRequired,
    deleteDeputy: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        openEditDeputyModal: (data) => {
            dispatch(toggleModal(ADMIN_EDIT_DEPUTY, data));
        },
        deleteDeputy: (data) => {
            return dispatch(deleteDeputyData(data));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeputyList);

const getColumns = (editDeputyData, deleteDeputyData, translate) => [
    {
        title: translate('adminPage.deputy.userTitle'),
        dataIndex: 'userEmail',
        key: 'userEmail',
    },
    {
        title: translate('adminPage.deputy.deputyTitle'),
        dataIndex: 'deputyEmail',
        key: 'deputyEmail',
    },
    {
        title: translate('adminPage.deputy.startDateTitle'),
        dataIndex: 'from',
        key: 'from',
        render: (text) => {
            return formatDate(text, translate('language'));
        },
    },
    {
        title: translate('adminPage.deputy.endDateTitle'),
        key: 'to',
        dataIndex: 'to',
        render: (text) => {
            return formatDate(text, translate('language'));
        },
    },
    {
        title: translate('adminPage.common.actions'),
        key: 'edit',
        // eslint-disable-next-line react/display-name
        render: (record) => (
            <Button.Group style={{ minWidth: '80px' }}>
                <Button
                    type={'default'}
                    title={translate('adminPage.deputy.edit')}
                    key={'edit'}
                    onClick={() => editDeputyData(record)}
                    icon={'edit'}
                />
                <DeleteButton
                    key={'delete'}
                    onClick={() =>
                        confirmationModal(
                            translate,
                            () => deleteDeputyData(record),
                            translate('confirm.deputyDeleteConfirm')
                        )
                    }
                    title={translate('adminPage.deputy.delete')}
                />
            </Button.Group>
        ),
    },
];
