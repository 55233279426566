/* eslint-disable react-hooks/exhaustive-deps */
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import UserProfileError from '../../UserProfileError';
import {
    WORKFLOW_TEMPLATE_MAX_LENGTH
} from '../../../constants/config';
import { Button, Col, Divider, Form, Icon, Input, Row, Spin } from 'antd';
import { createNotification, createNotificationShort, generateTemplateTitle } from '../../../helper';
import { getTranslate } from 'react-localize-redux';
import {
    getContainersInSignatureProcess,
    getContainers,
    SendForSignature
} from '../../../actions/container';
import { isBlank } from '../../../utilities/stringUtilities';
import { removeModal } from '../../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import theme from '../../../theme';
import { createWorkflowTemplate, getWorkflowTemplates } from '../../../actions/workflow';
import { WORKFLOW_ROLE_SIGNER } from '../../../constants/strings';
import SendForSignatureTitle from '../sign/SendForSignatureTitle';
import SigningWorkflowMetadataList from "./SigninWorkflowMetadataList";
import SigningWorkflowParticipantList from "./SigningWorkflowParticipantList";
import SigningWorkflowAttachments from "./SigningWorkflowAttachments";

function SendForSignatureModal(props) {
    const { form, template } = props;

    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [titleView, setTitleView] = useState(false);
    const [templateTitle, setTemplateTitle] = useState('');
    const [editedTemplateTitle, setEditedTemplateTitle] = useState('');
    const [commentValue, setCommentValue] = useState('');
    const [metaDataList, setMetaDataList] = useState([]);
    const [fileAttachmentList, setFileAttachmentList] = useState([]);
    const [participantList, setParticipantList] = useState([{ email: '', role: WORKFLOW_ROLE_SIGNER }]);

    const [conditionalTitleSpan, setConditionalTitleSpan] = useState(24);
    const [conditionalTitleEditSpan, setConditionalTitleEditSpan] = useState(0);
    const [conditionalTitleEditOffset, setConditionalTitleEditOffset] = useState(0);

    const containerIdList = useSelector((state) =>
        state.containerList.selectedContainers.map((container) => container.data.id)
    );
    const translate = useSelector((state) => getTranslate(state.locale));

    const [isLoaded, setIsLoaded] = useState(false);

    const dispatch = useDispatch();

    const iconFilled = favorite ? 'filled' : 'outlined';
    const titleSetStyle = template === undefined ? { fontSize: 12, color: theme.DEFAULT } : {
        fontSize: 12,
        color: theme.SECONDARY
    };

    useDeepCompareEffect(() => {
        form.resetFields();
    }, [form, metaDataList]);

    useEffect(() => {
        if (!isLoaded) {
            if (template === undefined) {
                dispatch(getWorkflowTemplates());
            } else {
                setFavorite(true);
                setTemplateTitle(template.templateTitle);
                setEditedTemplateTitle(template.templateTitle);
                setCommentValue(template.comment);
                setParticipantList(template.recipientList);
                setMetaDataList(getMappedMetaData(template.metadataList));
            }
        }
        if (!isBlank(templateTitle)) {
            setConditionalTitleSpan(18);
            setConditionalTitleEditSpan(2);
            setConditionalTitleEditOffset(2);
        } else {
            setConditionalTitleSpan(24);
            setConditionalTitleEditSpan(0);
            setConditionalTitleEditOffset(0);
        }
        setIsLoaded(true);
    }, [participantList, metaDataList, templateTitle, commentValue, props.modalData, isLoaded]);

    const getMappedMetaData = (metaDataList) => {
        let sortedList = [];
        metaDataList && metaDataList.forEach((element) => {
            sortedList.push({ key: element.data.key, value: element.data.value, mandatory: element.required });
        });
        return sortedList;
    };

    function submitForm(event) {
        event.preventDefault();
        form.validateFields((error, _) => {
            if (!error) {
                setDisabled(true);
                setLoading(true);

                const metadataMap = new Map();
                const metadataJSONForTemplate = [];
                for (let i = 0; i < metaDataList.length; i++) {
                    if (!isBlank(metaDataList[i].key)) {
                        metadataMap.set(metaDataList[i].key, metaDataList[i].value || '');
                        metadataJSONForTemplate.push({
                            'data': {
                                'key': metaDataList[i].key,
                                'value': metaDataList[i].value || ''
                            },
                            'required': !!metaDataList[i].mandatory,
                            'order': i
                        });
                    }
                }

                return dispatch(SendForSignature(containerIdList, participantList, false, commentValue, metadataMap, fileAttachmentList))
                    .then((response) => {
                        if (response.data.invalidTargetEmails.length === 0) {
                            createNotificationShort(translate, {
                                description: translate('notifications.sendForSignature.success.description'),
                                message: translate('notifications.sendForSignature.success.message'),
                                type: 'success'
                            });

                            dispatch(getContainers());
                            if (participantList.map((t) => t.email).includes(localStorage.getItem('user'))) {
                                dispatch(getContainersInSignatureProcess());
                            }
                        } else {
                            const ul = document.createElement('ul');
                            for (let i = 0; i < response.data.invalidTargetEmails.length; ++i) {
                                const li = document.createElement('li');
                                li.innerHTML = response.data.invalidTargetEmails[i];
                                ul.appendChild(li);
                            }
                            createNotificationShort(translate, {
                                description: translate('notifications.sendForSignature.invalidTargets.description', {
                                    emailAddresses: ul.outerHTML
                                }),
                                message: translate('notifications.sendForSignature.invalidTargets.message'),
                                type: 'error'
                            });
                        }
                        if (favorite && (template === undefined || template.templateTitle !== templateTitle)) {
                            dispatch(createWorkflowTemplate(templateTitle, participantList, metadataJSONForTemplate, commentValue));
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.containerNamesWithReason) {
                            error.response.data.containerNamesWithReason.forEach((containerNamesWithReason) => {
                                createNotification(translate, error.response.data.code, {
                                    message: translate(`errorCodes.${containerNamesWithReason.reason}`, {
                                        title: containerNamesWithReason.containerName
                                    }),
                                    type: 'error'
                                });
                            });
                        } else {
                            createNotificationShort(translate, {
                                message: translate('notifications.sendForSignature.failure'),
                                type: 'error'
                            });
                        }
                    })
                    .finally(() => dispatch(removeModal()));
            }
        });
    }

    function handleCommentChanged(newCommentValue) {
        setCommentValue(newCommentValue);
    }

    function handleFileAttachmentListChanged(newFileAttachmentList) {
        setFileAttachmentList(newFileAttachmentList);
    }

    function handleTemplateTitle() {
        let generated = generateTemplateTitle(participantList.map((p) => p.email));
        setTemplateTitle(generated);
        setEditedTemplateTitle(generated);
    }

    function isFeatureEnabled() {
        return localStorage.getItem('startWorkflow') === 'true';
    }

    if (!isFeatureEnabled()) {
        return (
            <UserProfileError
                icon={'user'}
                size={64}
            />
        );
    }

    return (
        <ModalWrapper
            {...props}
            title={<SendForSignatureTitle />}>
            <Spin
                size={'large'}
                spinning={loading}>
                <Form onSubmit={submitForm}>
                    <SigningWorkflowParticipantList
                        form={form}
                        originalParticipantList={participantList}
                        onParticipantChange={(newParticipantList) => {
                            setParticipantList(newParticipantList)
                        }}
                    />
                    <SigningWorkflowAttachments
                        form={form}
                        commentValue={commentValue}
                        handleCommentChanged={handleCommentChanged}
                        handleFileAttachmentListChanged={handleFileAttachmentListChanged}
                    />
                    {window.config.REACT_APP_META_DATA_ENABLED &&
                        <SigningWorkflowMetadataList
                            form={form}
                            originalMetadataList={metaDataList}
                            onMetadataChange={(newMetadataList) => {
                                setMetaDataList(newMetadataList)
                            }}
                        />
                    }
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} type='horizontal' />
                    <Form.Item>
                        {form.getFieldDecorator('templateTitle', {
                            initialValue: editedTemplateTitle ? editedTemplateTitle : null,
                            rules: [
                                { required: favorite, message: translate('form.required') },
                                {
                                    max: WORKFLOW_TEMPLATE_MAX_LENGTH,
                                    message: translate('form.text.tooLong', {
                                        length: WORKFLOW_TEMPLATE_MAX_LENGTH
                                    })
                                }
                            ]
                        })(
                            titleView ?
                                (
                                    <Row>
                                        <Col span={18}>
                                            <Input
                                                maxLength={255}
                                                onChange={(e) => {
                                                    setEditedTemplateTitle(e.target.value);
                                                }}
                                                value={editedTemplateTitle}
                                                placeholder={translate('workflow.template.placeholder')}
                                            />
                                        </Col>
                                        <Col span={2} offset={2}>
                                            <Button style={{ border: 'none', boxShadow: 'none' }}
                                                    type='primary' icon='check'
                                                    onClick={() => {
                                                        setTitleView(false);
                                                        setTemplateTitle(editedTemplateTitle);
                                                    }} />
                                        </Col>
                                        <Col span={2}>
                                            <Button type='primary' icon='close' ghost
                                                    onClick={() => {
                                                        setTitleView(false);
                                                    }} />
                                        </Col>
                                    </Row>
                                ) :
                                (
                                    <div>
                                        <Row>
                                            <Col style={{ color: theme.primaryColor }}>
                                                <Button style={{ border: 'none', boxShadow: 'none' }} type='primary'
                                                        ghost
                                                        onClick={() => {
                                                            if (!isBlank(participantList[0].email)) {
                                                                if (!favorite) {
                                                                    isBlank(templateTitle) && handleTemplateTitle();
                                                                } else {
                                                                    setTitleView(false);
                                                                    setEditedTemplateTitle('');
                                                                    setTemplateTitle('');
                                                                }

                                                                setFavorite(!favorite);
                                                            }
                                                        }}><Icon theme={iconFilled} type='star' />
                                                    {favorite ? translate('workflow.template.saveAndSet') : translate('workflow.template.save')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={conditionalTitleSpan} style={{ marginLeft: 20 }}>
                                                <div style={titleSetStyle}>
                                                    {isBlank(templateTitle)
                                                        ? translate('workflow.template.saveDescription')
                                                        : templateTitle
                                                    }
                                                </div>
                                            </Col>
                                            {!isBlank(templateTitle) &&
                                            <Col span={conditionalTitleEditSpan} offset={conditionalTitleEditOffset}>
                                                <Button icon='edit'
                                                        style={{
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                            color: 'black'
                                                        }}
                                                        onClick={() => {
                                                            !isBlank(templateTitle) && setTitleView(true);
                                                            setEditedTemplateTitle(templateTitle);
                                                        }} />
                                            </Col>}
                                        </Row>
                                    </div>
                                )
                        )
                        }
                    </Form.Item>
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} type='horizontal' />
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Row>
                            <Col span={10} offset={14}>
                                <Button
                                    block={true}
                                    disabled={disabled}
                                    htmlType={'submit'}
                                    id={'send-for-signature-button'}
                                    type={'primary'}>
                                    {translate('modals.sendForSignature.button')}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Spin>
        </ModalWrapper>
    );
}

SendForSignatureModal.propTypes = {
    form: PropTypes.object.isRequired,
    template: PropTypes.object
};

const WrappedSendForSignatureModal = Form.create(
    {
        name: 'send-for-signature-modal'
    }
)(SendForSignatureModal);
export default WrappedSendForSignatureModal;
