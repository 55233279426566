/* eslint-disable react-hooks/exhaustive-deps */
import CertificateProperties from '../widget/CertificateProperties';
import CertificateSelect from '../widget/CertificateSelect';
import DeleteButton from '../buttons/DeleteButton';
import { ERROR_LEVEL_WARNING } from '../../constants/errorLevel';
import { ajax, createNotification, createNotificationShort } from '../../helper';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { Checkbox, Comment, Spin } from "antd";
import PropTypes from "prop-types";

function UserCertificateView(props) {
    const [defaultUserCertificateProperties, setDefaultUserCertificateProperties] = useState();
    const [attributeCertCheckbox, setAttributeCertCheckbox] = useState(localStorage.getItem('useAttributeCert'));
    const translate = useSelector((state) => getTranslate(state.locale));
    const activeLanguageCode = useSelector((state) => getActiveLanguage(state.locale).code);
    const userSignModeTitle = props.signMode ==='sign' ? translate('hint.defaultUserCertificateSelect') : translate('hint.attributeUserCertificateSelect');
    const userSignModeTitleDelete = props.signMode ==='sign' ? translate('hint.defaultUserCertificateDelete') : translate('hint.attributeUserCertificateDelete');

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = () => {
        // TODO Az ajax() hibakezelését rendbe kell tenni.
        ajax()
            .get('/user/certificate/properties')
            .then((response) => {
                setDefaultUserCertificateProperties(response.data);
            })

            .catch((error) => {
                if (error.response.data.error.code === 'HTTP_CLIENT_ERROR_NOT_FOUND') {
                    console.info('No user certificate set.');
                } else {
                    createNotification(translate, error.response.data.code, {
                        description: translate('messages.error.userCertificateSettings'),
                        message: translate('titles.defaultUserCertificateError'),
                        type: 'error'
                    });
                }
                setDefaultUserCertificateProperties(null);
            });
    };

    const handleUserCertificateSelected = (sessionId) => {
        console.info(`User certificate has selected in session '${sessionId}'.`);

        ajax()
            .post(`/user/certificate/selected/${sessionId}`)
            .then(() => {
                console.info('Selected user certificate has set.');

                setDefaultUserCertificateProperties(undefined);
                fetchSettings();
            })
            .catch((error) => {
                console.error(
                    `Unable to set default certificate selected in session '${sessionId}': ${error.message}.`
                );
                if (error.response.data.code === 'USER_CERTIFICATE_NOT_AUTHORIZED') {
                    createNotification(translate, error.response.data.code, {
                        message: translate(`errorCodes.USER_CERTIFICATE_NOT_AUTHORIZED_TO_SELECT`),
                        type: 'error'
                    });
                } else {
                    createNotification(translate, error.response.data.code, {
                        description: translate('messages.error.defaultUserCertificateSet'),
                        message: translate('titles.defaultUserCertificateError'),
                        type: 'error'
                    });
                }
            });
    }

    const handleUserCertificateSelectError = (level, errorCode) => {
        createNotification(translate, errorCode, {
            description: translate(`messages.error.certificateSelect.${errorCode}`),
            message: translate('titles.defaultUserCertificateSelectError'),
            type: level === ERROR_LEVEL_WARNING ? 'warning' : 'error'
        });
    }

    const handleDefaultUserCertificateDelete = (event) => {
        // Click on the button only. Prevent click event on the parent of the button.
        event.stopPropagation();

        console.debug('Deleting default user certificate of current user...');

        ajax()
            .delete('/user/certificate')
            .then(() => {
                console.info('Default user certificate of current user has deleted.');
                setDefaultUserCertificateProperties(null);
            })
            .catch((error) => {
                console.error(`Unable to delete default user certificate of current user: ${error.message}.`);

                createNotification(translate, error.response.data.code, {
                    description: translate('messages.error.defaultUserCertificateDelete'),
                    message: translate('titles.defaultUserCertificateDeleteError'),
                    type: 'error'
                });
            });
    };

    const setUseAttributeCert = (setting) => {
        return ajax()
            .post(`/attributeCertificate?useAttributeCert=${setting.target.checked}`)
            .then((response) => {
                localStorage.setItem('useAttributeCert', response.data.useAttributeCert);
                setAttributeCertCheckbox(response.data.useAttributeCert);
                createNotificationShort(translate, {
                    message: translate('notifications.useAttributeCert.success'),
                    type: 'success'
                });
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate('notifications.useAttributeCert.failure'),
                    type: 'error'
                });
            });
    };

    return (
        <React.Fragment>
            {defaultUserCertificateProperties === undefined && (
                <Spin
                    size={'default'}
                    style={{ display: 'block' }}
                />
            )}

            {defaultUserCertificateProperties === null && (
                <CertificateSelect
                    activeLanguageCode={activeLanguageCode}
                    eSignMessage={translate('esign.message.defaultUserCertificateSelect')}
                    showDetails={true}
                    signMode={props.signMode}
                    text={translate('button.defaultUserCertificateSelect')}
                    title={userSignModeTitle}
                    onCertificateSelected={handleUserCertificateSelected}
                    onCertificateSelectError={handleUserCertificateSelectError}
                />
            )}

            {defaultUserCertificateProperties && (
                <CertificateProperties
                    certificateProperties={defaultUserCertificateProperties}
                    titleExtra={
                        <DeleteButton
                            onClick={handleDefaultUserCertificateDelete}
                            size={'small'}
                            title={userSignModeTitleDelete}
                        />
                    }
                />
            )}
            {window.config.REACT_APP_ATTRIBUTE_CERT_USAGE && (
                <div>
                    <Checkbox
                        onChange={setUseAttributeCert}
                        checked={attributeCertCheckbox === true || attributeCertCheckbox === 'true'}
                        style={{ textAlign: 'left', fontWeight: 'bold', marginTop: '15px', width: '100%' }}>
                        {translate('attributeCertificate.checkBoxTitle')}
                    </Checkbox>
                    <Comment content={translate('attributeCertificate.description')} />
                </div>
            )}
        </React.Fragment>
    );
}

UserCertificateView.propTypes = {
    signMode: PropTypes.string.isRequired
};

export default UserCertificateView;
