import AckVerify from './AckVerify';
import DeleteContainerButton from './buttons/DeleteContainerButton';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import RefuseSignButton from './buttons/RefuseSignButton';
import SignHidden from './SignHidden';
import authService from '../services/authService';
import {Button, Divider, Dropdown, Icon, Menu, notification} from 'antd';
import {addTimeStampPDFAction, checkTsp} from '../actions/tsa';
import {
    ajax,
    createNotification,
    createNotificationShort,
    getHistory,
    getSignModeIconType,
    isCegeljaras,
    removeFilenameCounterPostfix,
    shouldSignAuto,
    validateFileSize
} from '../helper';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {isEmpty} from 'lodash';
import {toggleModal} from '../actions/modal';
import {checkCompanyRegistrationDocument, uploadCompanyRegistrationDocument, uploadDocument} from '../actions/document';
import {confirmationModal} from './modals/ConfirmationModal';
import {approveContainers, getContainers, getContainerSignatures, getContainersInSignatureProcess, updateSelectedContainerList} from '../actions/container';
import {TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS, TAB_KEY_DEFAULT_CONTAINERS} from '../constants/strings';
import {getMetadata, getWorkflowTemplates} from '../actions/workflow';
import lawyerIcon from '../images/lawyer.svg';
import {AUTHENTICATION, COMPANY_REGISTRATION, META_DATA, PDF_WITH_FORM_NOTIFICATION, RENAME_CONTAINER, SEND_FOR_SIGNATURE, SHARE_CONTAINER, TSA, UPLOAD_CEGELJARAS, USER_PROFILE_ERROR, WORKFLOW_TEMPLATE} from '../constants/modalNames';

export class DocumentControls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionType: '',
            uploading: false,
            uploadFile: null,
            imageWidth: 0,
            imageHeight: 0,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (window.config.REACT_APP_TIMESTAMP_LOGIN_MODE !== 'microsec') {
            this.props.checkUserTspCredentials(this.props.translate);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (this.props.tsaReq !== prevProps.tsaReq && this.props.tsaReq === false) {
            if (this.state.actionType === 'addTsp') {
                this.props.addTimeStamp(this.props.container.id, this.props.translate);
                this._isMounted && this.setState({ actionType: '' });
            }
        }
    }

    setTitle = (newTitle) => {
        let { uploadFile } = this.state;
        let file = uploadFile;
        file = new File([file], uploadFile.name, { type: file.type });
        const formData = new FormData();
        formData.append('title', new Blob([newTitle], { type: 'text/plain;charset=utf8' }));
        formData.append('file', file);
        this.props
            .uploadCompanyRegistrationDocument(this.props.container.id, formData)
            .then(() => {
                createNotificationShort(this.props.translate, {
                    description: this.props.translate('notifications.uploadOK.description', { fileName: file.name }),
                    message: this.props.translate('notifications.uploadOK.message'),
                    type: 'succes',
                });
            })
            .catch((err) => {
                const error = { ...err.response.data.error };
                let description;
                if (error.code === 'USER_ECEGELJARAS_FILE_NOT_SIGNED') {
                    description = this.props.translate(`errorCodes.${error.code}`, { title: error.detail });
                } else if (error.code === 'USER_ECEGELJARAS_INVALID_DOSSIER_TITLE') {
                    description = this.props.translate(`errorCodes.${error.code}`);
                } else if (error.code === 'USER_INVALID_PARAMETERS') {
                    description = this.props.translate('notifications.uploadFailure.badFormat', {
                        fileName: file.name,
                    });
                } else {
                    description = this.props.translate('notifications.uploadFailure.description', {
                        fileName: file.name,
                    });
                }
                createNotification(this.props.translate, err.response.data.code, {
                    description: description,
                    message: this.props.translate('notifications.uploadFailure.message'),
                    type: 'error',
                });
            });
    };

    handleDocumentUpload = async (event) => {
        const {
            container,
            companyRegistrationModal,
            uploadDocumentAction,
            setTableLoadingTrue,
            setTableLoadingFalse,
            checkCompanyRegistrationDocumentAction,
        } = this.props;
        ReactGA.event({
            category: 'User',
            action: 'Document Upload',
        });
        const eventFiles = [...event.target.files];
        this.uploadDocument.value = null;
        try {
            validateFileSize(eventFiles[0]);
            if (isCegeljaras(container)) {
                try {
                    setTableLoadingTrue();
                    let fData = new FormData();
                    const file = eventFiles[0];
                    fData.append('cegeljaras_document', file);
                    const res = await checkCompanyRegistrationDocumentAction(container.id, fData);

                    if (!isEmpty(res.data)) {
                        // nem üres ha a dokumentum 'költség' vagy 'illeték' volt
                        createNotificationShort(this.props.translate, {
                            description: this.props.translate('notifications.uploadOK.description', {
                                fileName: file.name,
                            }),
                            message: this.props.translate('notifications.uploadOK.message'),
                            type: 'success',
                        });
                        return;
                    }
                } catch (e) {
                    createNotificationShort(this.props.translate, {
                        message: this.props.translate('notifications.uploadFailure.message'),
                        type: 'error',
                    });
                    return;
                } finally {
                    setTableLoadingFalse();
                }

                const file = eventFiles[0];
                this._isMounted && this.setState({ uploadFile: file });
                companyRegistrationModal({
                    setTitle: this.setTitle,
                    fileName: file.name,
                });
            } else {
                setTableLoadingTrue();
                Array.from(eventFiles).forEach((file) => {
                    const formData = new FormData();
                    formData.append('file', file);
                    uploadDocumentAction(container.id, formData)
                        .then((res) => {
                            createNotificationShort(this.props.translate, {
                                description: this.props.translate('notifications.uploadOK.description', {
                                    fileName: file.name,
                                }),
                                message: this.props.translate('notifications.uploadOK.message'),
                                type: 'success',
                            });
                            const originalFilename = file.name;

                            if (removeFilenameCounterPostfix(originalFilename) !== removeFilenameCounterPostfix(res.data.details.documents.slice(-1)[0].title)) {
                                notification.open({
                                    message: this.props.translate('notifications.uploadOK.fileNameChanged'),
                                    type: 'info',
                                    duration: 10
                                });
                            }
                        })
                        .catch((err) => {
                            throw err;
                        })
                        .finally(() => {
                            setTableLoadingFalse();
                        });
                });
                // Reset <input type="file" ..> to upload the same file multiple times (onChange do not trigger if this is not set)
                event.target.value = null;
            }
        } catch (error) {
            if (error.message === 'USER_MAX_UPLOAD_SIZE_ERROR') {
                createNotification(this.props.translate, error.message, {
                    description: this.props.translate(`errorCodes.${error.message}`),
                    message: this.props.translate('notifications.uploadFailure.message'),
                    type: 'error',
                });
            } else {
                createNotification(this.props.translate, error.message, {
                    message: this.props.translate('notifications.uploadFailure.message'),
                    type: 'error',
                });
            }
        }
    };

    onDownload = () => {
        let containerId = this.props.container.id;
        ajax()
            .get(`${window.config.REACT_APP_SERVICE_BASE_URL}/containers/${containerId}/download`, {
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: `${response.headers['content-type']};charset=UTF-8`,
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                const headerval = response.headers['content-disposition'];
                const filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
                link.setAttribute('download', decodeURI(filename));
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                createNotification(this.props.translate, error.response.data.code, {
                    message: this.props.translate('notifications.downloadDocument'),
                    type: 'error',
                });
            });
    };

    isSignatureDisabled = (containerType) => {
        const { isMultipleFileSelectEnabled, isContainerSelected, hasDocuments, container } = this.props;
        return (
            !isContainerSelected ||
            (containerType === 'asic' && !hasDocuments && !isMultipleFileSelectEnabled) ||
            container.error !== undefined
        );
    };

    approve = () => {
        const { container, approveContainer, updateContainersWaiting, updateContainers, translate } = this.props;
        confirmationModal(
            translate,
            () =>
                approveContainer([container.id])
                    .then(() => {
                        createNotificationShort(this.props.translate, {
                            message: translate('notifications.approve.success'),
                            type: 'info',
                        });
                        updateContainersWaiting();
                        updateContainers();
                    })
                    .catch((e) => {
                        createNotification(this.props.translate, e.response.data.code, {
                            message: translate('notifications.approve.failure'),
                            type: 'error',
                        });
                    }),
            translate('confirm.approve')
        );
    };

    emulateSignClickBasedOnRegistration = (containerType, isCounterSignature, isClosure) => {
        const { sign, authenticationModal, userProfileErrorModal, container, currentTab, showPdfFormModal } = this.props;
        if (containerType === 'pdf' && localStorage.getItem('signPDF') === 'false') {
            userProfileErrorModal();
            return;
        }
        if (
            (containerType === 'asic' || containerType === 'edossier') &&
            localStorage.getItem('signContainers') === 'false'
        ) {
            userProfileErrorModal();
            return;
        }
        if (shouldSignAuto(container)) {
            showPdfFormModal({
                data: { container: container, currentTab: currentTab, showPdfPreview: true }
            });
            return;
        }

        authService.isRegistered() ? sign(container.id, isCounterSignature, isClosure) : authenticationModal();
    };

    signButtonLayout = (containerType, isContainerWaitingForApproval) => {
        const {
            companyRegModal,
            container,
            currentTab,
            isMultipleFileSelectEnabled,
            isSigned,
            translate
        } = this.props;

        const signButtonText = isContainerWaitingForApproval ? 'sendForSignature.approve' : 'buttons.sign.text';

        if (containerType === 'eacknowledgement' && !isMultipleFileSelectEnabled) {
            return <AckVerify />;
        } else if (
            ((containerType === 'pdf' && !isSigned) || containerType === 'edossier') &&
            authService.isRegistered() &&
            !isContainerWaitingForApproval
        ) {
            return (
                <Dropdown.Button
                    id={'additionalSign'}
                    type={'primary'}
                    disabled={this.isSignatureDisabled(containerType)}
                    onClick={() => {
                        this.emulateSignClickBasedOnRegistration(containerType, false)
                    }}
                    trigger={['click']}
                    overlay={
                        <Menu>
                                <Menu.Item
                                    key='1'
                                    onClick={() => companyRegModal({ containers: [{ data: container }], currentTab })}>
                                    <Icon type='form' />
                                    {translate('buttons.sign.companyReg')}
                                </Menu.Item>
                            {containerType === 'edossier' && (
                                //container.isCounterSignable && -- TODO
                                <Menu.Item
                                    key='2'
                                    onClick={() => {
                                        this.emulateSignClickBasedOnRegistration(containerType, true)
                                    }}>
                                    <img
                                        alt=''
                                        src={lawyerIcon}
                                        style={{ marginBottom: 4, marginRight: 6, width: 14, height: 14 }}
                                    />
                                    {translate('buttons.countersign.text')}
                                </Menu.Item>
                            )}
                            {containerType === 'edossier' && (
                                <Menu.Item
                                    key='3'
                                    onClick={() => {
                                        this.emulateSignClickBasedOnRegistration(containerType, false, true)
                                    }}>
                                    <Icon type='lock' />
                                    {translate('titles.signWithComment')}
                                </Menu.Item>
                            )}
                        </Menu>
                    }>
                    <Icon type={getSignModeIconType()} />
                    {translate('buttons.sign.text')}
                </Dropdown.Button>
            );
        } else {
            return (
                <Button
                    id={'simpleSign'}
                    type={'primary'}
                    disabled={this.isSignatureDisabled(containerType)}
                    onClick={() =>
                        isContainerWaitingForApproval
                            ? this.approve(containerType)
                            : this.emulateSignClickBasedOnRegistration(containerType)
                    }>
                    {!isContainerWaitingForApproval &&
                        <Icon type={getSignModeIconType()} />
                    }
                    {translate(signButtonText)}
                </Button>
            );
        }
    };

    /**
     * When called, redirects the user to the related signature process,
     * based on the selected container.
     */
    onRedirectToSignatureProcess = () => {
        const { isContainerSelected } = this.props;

        if (!isContainerSelected) {
            return;
        }

        getHistory().push('/waitingToSignByOthers');
    };

    render() {
        const {
            translate,
            documents,
            hasDocuments,
            isContainerSigned,
            container,
            tsaReq,
            isContainerSelected,
            currentTab,
            isDeprecatedCompanyRegistrationSchema,
            isUploadDisabled
        } = this.props;
        let menu = [];
        let containerType = undefined;
        const isDefaultContainer = currentTab === TAB_KEY_DEFAULT_CONTAINERS;
        const isContainerWaitingForApproval = container.action === 'approve';
        const refuseButtonText = isContainerWaitingForApproval
            ? translate('buttons.delete.containerToApprove')
            : translate('buttons.delete.containerToSign');
        if (!isEmpty(container)) {
            containerType = container.type;
            const uriType = containerType;
            const isNotPDF = ((uriType === 'edossier' && containerType !== 'et3') || uriType === 'asic');
            const renameDisabled =
                uriType === 'edossier' && (isContainerSigned || isDeprecatedCompanyRegistrationSchema);
            menu = (
                <Menu>
                    { isNotPDF &&
                        <Menu.Item
                            key='insertDocument'
                            disabled={isUploadDisabled}
                            onClick={() => this.uploadDocument.click()}>
                            <input
                                id={'upload-document-controls'}
                                multiple={!isCegeljaras(container)}
                                onChange={this.handleDocumentUpload}
                                ref={(ref) => (this.uploadDocument = ref)}
                                style={{ display: 'none' }}
                                type={'file'}
                            />
                            {uriType === 'edossier'
                                ? translate('menus.container.edossier.insert')
                                : translate('menus.container.insertDocument')}
                        </Menu.Item>
                    }
                    { isNotPDF &&
                        <Menu.Item
                            key='downloadAllDocuments'
                            disabled={!hasDocuments}
                            onClick={() => {
                                ajax()
                                    .get(
                                        `${window.config.REACT_APP_SERVICE_BASE_URL}/containers/${container.id}/documents/downloadAll`,
                                        {
                                            responseType: 'blob',
                                        }
                                    )
                                    .then((response) => {
                                        const url = window.URL.createObjectURL(
                                            new Blob([response.data], {
                                                type: `${response.headers['content-type']};charset=UTF-8`,
                                            })
                                        );
                                        const link = document.createElement('a');
                                        link.href = url;
                                        const headerval = response.headers['content-disposition'];
                                        const filename = headerval ? headerval
                                            .split(';')[1]
                                            .split('=')[1]
                                            .replace('"', '')
                                            .replace('"', '') : "documents.zip";
                                        link.setAttribute('download', decodeURI(filename));
                                        document.body.appendChild(link);
                                        link.click();
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                            }}>
                            {uriType === 'edossier'
                                ? translate('menus.container.edossier.download')
                                : translate('menus.container.downloadAllDocuments')}
                        </Menu.Item>
                    }
                    <Menu.Item
                        disabled={true}
                        key={'divider'}
                        style={{ padding: 0 }}>
                        <Divider style={{ margin: 0 }} />
                    </Menu.Item>
                    <Menu.Item
                        key='rename'
                        disabled={renameDisabled}
                        onClick={this.props.renameContainerAction}>
                        {this.props.translate('menus.container.rename')}
                    </Menu.Item>
                    {authService.isRegistered() && (
                        <Menu.Item
                            key='sendInEmail'
                            onClick={() => {
                                this.props.sendEmailModal(container.id, translate);
                            }}>
                            {translate('menus.container.sendEmail')}
                        </Menu.Item>
                    )}
                    {uriType === 'pdf' && (
                        <Menu.Item
                            key='addTS'
                            onClick={() => {
                                this._isMounted && this.setState({ actionType: 'addTsp' });
                                this.props.checkTspAndAddTimeStamp(container.id, translate, tsaReq);
                            }}>
                            {this.props.translate('menus.container.timestamp')}
                        </Menu.Item>
                    )}
                    {authService.isRegistered() && containerType !== 'et3' && (
                        <Menu.Item
                            disabled={
                                (containerType === 'asic' && documents.length === 0) ||
                                containerType === 'et3' ||
                                containerType === 'eacknowledgement'
                            }
                            key='sendForSignature'
                            onClick={() => {
                                this.props.sendForSignatureModal(container.id, translate);
                            }}>
                            {translate('modals.sendForSignature.title')}
                        </Menu.Item>
                    )}
                    {authService.isRegistered() && containerType !== 'et3' && (
                        <Menu.Item
                            disabled={
                                (containerType === 'asic' && documents.length === 0) ||
                                containerType === 'et3' ||
                                containerType === 'eacknowledgement'
                            }
                            key='templates'
                            onClick={() => {
                                this.props.showWorkflowTemplatesModal();
                            }}>
                            {translate('workflow.template.title')}
                        </Menu.Item>
                    )}
                    {authService.isRegistered() && (
                        <Menu.Item
                            key='createAcknowledgement'
                            disabled={isDeprecatedCompanyRegistrationSchema || containerType === 'eacknowledgement'}
                            onClick={() => {
                                this.props.createAcknowledgement(container.id);
                            }}>
                            {translate('menus.container.createAcknowledgement')}
                        </Menu.Item>
                    )}
                </Menu>
            );
        }

        return (
            <div style={{ marginBottom: 10 }}>
                <SignHidden
                    container={container}
                    pdfFormSigning={false}
                    imageHeight={this.state.imageHeight}
                    imageWidth={this.state.imageWidth}
                    idPrefix={container.id}
                />
                {this.signButtonLayout(containerType, isContainerWaitingForApproval)}
                <Divider type='vertical' />
                <Button
                    type={'default'}
                    key={'download'}
                    title={translate('buttons.download.container')}
                    onClick={this.onDownload}
                    icon={'download'}
                    disabled={!isContainerSelected}
                />
                <Divider type='vertical' />
                {isDefaultContainer ? (
                    <DeleteContainerButton disabled={!isContainerSelected} />
                ) : (
                    <RefuseSignButton
                        buttonText={refuseButtonText}
                        disabled={!isContainerSelected}
                    />
                )}
                {currentTab === TAB_KEY_DEFAULT_CONTAINERS && (
                    <React.Fragment>
                        <Divider type='vertical' />
                        <Dropdown
                            trigger={['click']}
                            overlay={menu}
                            disabled={container.error !== undefined}>
                            <Button title={translate('buttons.fileOptions')}>
                                <Icon type='ellipsis' />
                            </Button>
                        </Dropdown>
                    </React.Fragment>
                )}
                {currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS && (
                    <React.Fragment>
                        <Divider type={'vertical'} />
                        <Button
                            type={'default'}
                            title={translate('buttons.redirectToSignatureProcess')}
                            onClick={this.onRedirectToSignatureProcess}
                            icon={'forward'}>
                            {translate('buttons.redirectToSignatureProcess')}
                        </Button>
                        {window.config.REACT_APP_META_DATA_ENABLED && (
                            <React.Fragment>
                                <Divider type={'vertical'} />
                                <Button
                                    type={'default'}
                                    title={translate('tooltip.metadata')}
                                    onClick={() => this.props.openMetaDataModal(container.workflowGuid)}
                                    icon={'database'}>
                                    {translate('titles.metaData')}
                                </Button>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

DocumentControls.propTypes = {
    addTimeStamp: PropTypes.func.isRequired,
    authenticationModal: PropTypes.func.isRequired,
    checkTspAndAddTimeStamp: PropTypes.func.isRequired,
    checkUserTspCredentials: PropTypes.func.isRequired,
    companyRegistrationModal: PropTypes.func.isRequired,
    companyRegModal: PropTypes.func.isRequired,
    container: PropTypes.object,
    createAcknowledgement: PropTypes.func.isRequired,
    currentTab: PropTypes.string.isRequired,
    documents: PropTypes.array.isRequired,
    isMultipleFileSelectEnabled: PropTypes.bool.isRequired,
    isContainerSelected: PropTypes.bool.isRequired,
    isContainerSigned: PropTypes.bool.isRequired,
    isDeprecatedCompanyRegistrationSchema: PropTypes.bool.isRequired,
    isSigned: PropTypes.bool.isRequired,
    hasDocuments: PropTypes.bool.isRequired,
    renameContainerAction: PropTypes.func.isRequired,
    sendEmailModal: PropTypes.func.isRequired,
    sign: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    tsaReq: PropTypes.any,
    uploadCompanyRegistrationDocument: PropTypes.func.isRequired,
    uploadDocumentAction: PropTypes.func.isRequired,
    userProfileErrorModal: PropTypes.func.isRequired,
    setTableLoadingFalse: PropTypes.func,
    setTableLoadingTrue: PropTypes.func,
    checkCompanyRegistrationDocumentAction: PropTypes.func,
    showPdfFormModal: PropTypes.func.isRequired,
    approveContainer: PropTypes.func.isRequired,
    updateContainers: PropTypes.func.isRequired,
    containersInSignatureProcess: PropTypes.array.isRequired,
    updateContainersWaiting: PropTypes.func.isRequired,
    sendForSignatureModal: PropTypes.func.isRequired,
    openMetaDataModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        containersInSignatureProcess: state.containerList.containersInSignatureProcess,
        currentTab: state.tabs.current,
        documents: state.documentList.documents,
        isMultipleFileSelectEnabled: state.enabled.checkbox,
        isContainerSelected: state.containerList.selectedContainers.length > 0,
        isModalVisible: state.modal.visible,
        isSigned: state.containerList.signatures.length > 0,
        translate: getTranslate(state.locale),
        tsaReq: state.tsa.requireTspCredentials
    };
}

function mapDispatchToProps(dispatch) {
    const addTsa = (containerId, translate) => {
        /* Notification until a destroy call */
        createNotificationShort(translate, {
            message: translate('notifications.timestampBegin.message'),
            type: 'info',
        });
        ReactGA.event({
            category: 'User',
            action: 'Add Timestamp on PDF',
        });
        dispatch(addTimeStampPDFAction(containerId))
            .then(() => {
                dispatch(getContainerSignatures('pdf', containerId));
            })
            .then(() => {
                notification.destroy();
                createNotificationShort(translate, {
                    message: translate('notifications.timestampSuccess.message'),
                    type: 'success',
                });
            })
            .catch((e) => {
                notification.destroy();
                createNotification(translate, e.response.data.code, {
                    message: translate('notifications.timestampFailure.message'),
                    type: 'error',
                });
            });
    };

    return {
        updateContainersWaiting: () => {
            dispatch(getContainersInSignatureProcess()).then((containersInSignatureProcess) => {
                const containers = containersInSignatureProcess.containers;
                if (containers.length > 0) {
                    dispatch(updateSelectedContainerList([containers[0]]));
                }
            });
        },
        updateContainers: () => {
            dispatch(getContainers());
        },
        uploadDocumentAction: (id, document) => {
            return dispatch(uploadDocument(id, document));
        },
        companyRegistrationModal: (file) => {
            dispatch(toggleModal(UPLOAD_CEGELJARAS, file));
        },
        sendEmailModal: () => {
            dispatch(toggleModal(SHARE_CONTAINER));
        },
        renameContainerAction: () => {
            dispatch(toggleModal(RENAME_CONTAINER));
        },
        checkTspAndAddTimeStamp: (containerId, translate, requireTspCredentials) => {
            if (window.config.REACT_APP_TIMESTAMP_LOGIN_MODE === 'microsec' || !requireTspCredentials) {
                addTsa(containerId, translate);
            } else if (requireTspCredentials) {
                dispatch(toggleModal(TSA));
            }
        },
        addTimeStamp: (containerId, translate) => {
            addTsa(containerId, translate);
        },
        checkUserTspCredentials: (translate) => {
            dispatch(checkTsp()).catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: translate('notifications.getTsaData.failure'),
                    type: 'error',
                });
            });
        },
        createAcknowledgement: (containerId) => {
            document.getElementById(containerId + '_containerCreateAcknowledgement').click();
        },
        sign: (containerId, isCounterSignature, closure) => {
            if (isCounterSignature) {
                document.getElementById(containerId + '_containerCounterSign').click();
            } else if (closure) {
                document.getElementById(containerId + '_containerSignWithCommentdoc').click();
            } else {
                document.getElementById(containerId + '_containerSign').click();
            }
        },
        authenticationModal: () => {
            dispatch(toggleModal(AUTHENTICATION));
        },
        uploadCompanyRegistrationDocument: (id, data) => {
            return dispatch(uploadCompanyRegistrationDocument(id, data));
        },
        companyRegModal: (data) => {
            return dispatch(toggleModal(COMPANY_REGISTRATION, data));
        },
        userProfileErrorModal: () => {
            dispatch(toggleModal(USER_PROFILE_ERROR));
        },
        checkCompanyRegistrationDocumentAction: (containerId, formData) => {
            return dispatch(checkCompanyRegistrationDocument(containerId, formData));
        },
        showPdfFormModal: (data) => {
            return dispatch(toggleModal(PDF_WITH_FORM_NOTIFICATION, data));
        },
        approveContainer: (containerIds) => {
            return dispatch(approveContainers(containerIds));
        },
        sendForSignatureModal: () => {
            dispatch(toggleModal(SEND_FOR_SIGNATURE));
        },
        openMetaDataModal: (guid) => {
            dispatch(getMetadata(guid)).then((data) => {
                dispatch(toggleModal(META_DATA, data));
            });
        },
        showWorkflowTemplatesModal: () => {
            dispatch(getWorkflowTemplates()).then(() => dispatch(toggleModal(WORKFLOW_TEMPLATE)));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentControls);
