import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

function Box(props) {
    const { borderRadius, children, padding, margin } = props;
    return (
        <div
            className={'box'}
            style={{
                padding: padding ? padding : 40,
                margin: margin ? margin : '',
                backgroundColor: theme.boxBgColor,
                borderRadius: borderRadius ? borderRadius : 4,
                boxShadow: `0 2px 8px 1px ${theme.DISABLED}`,
                border: `solid 1px ${theme.DISABLED}`,
            }}
        >
            {children}
        </div>
    );
}

Box.propTypes = {
    borderRadius: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    padding: PropTypes.number,
    margin: PropTypes.string
};

export default Box;
