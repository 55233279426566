import Box from './Box';
import DeleteButton from './buttons/DeleteButton';
import FormItem from './form/FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import WebPage from './WebPage';
import { ajax, createNotification, createNotificationShort, formatDate } from '../helper';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { getUser } from '../actions/admin';
import { getTranslate } from 'react-localize-redux';
import { Col, Icon, Row, Button, Input, Form, Select, Modal, Divider, Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import authService from '../services/authService';

class EditUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            editedUser: {},
            credentialType: '',
            editModeEmail: false,
            editModeUserRole: false,
            editModeCredentialType: false,
            editModeOid: false,
            editModeLanguage: false,
            editModeTSA: false,
            editModePassword: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { match } = this.props;
        if (match.params.email !== undefined) {
            this.props
                .getUser(match.params.email)
                .then((response) => {
                    this._isMounted &&
                        this.setState({
                            user: response.data,
                            editedUser: response.data,
                            credentialType: response.data.credentialType,
                        });
                })
                .catch((e) => {
                    createNotification(this.props.translate, e.response.data.code, {
                        message: this.props.translate('notifications.getData.failure'),
                        type: 'error',
                    });
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    credentialChanged = (value) => {
        this._isMounted && this.setState({ credentialType: value });
    };

    editButton = (inputKey) => {
        return (
            <Button
                id={`${inputKey}_edit`}
                type={'primary'}
                key={'edit'}
                size={'small'}
                disabled={isEmpty(this.state.editedUser)}
                onClick={() => this._isMounted && this.setState({ [inputKey]: true })}
                icon={'edit'}
                style={{ float: 'right' }}
            />
        );
    };

    saveCancelButtons = (inputKey) => (
        <div style={{ float: 'right' }}>
            <Button
                id={`${inputKey}_save`}
                type={'primary'}
                key={'save'}
                size={'small'}
                icon={'save'}
                htmlType="submit"
            />
            <Divider type="vertical" />
            <DeleteButton
                icon={'close'}
                id={`${inputKey}_cancel`}
                key={'delete'}
                onClick={() => this._isMounted && this.setState({ [inputKey]: false, editedUser: this.state.user })}
                size={'small'}
                title={this.props.translate('buttons.cancel')}
            />
        </div>
    );

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, translate } = this.props;
        const userEmail = this.state.editedUser.email;
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                Modal.confirm({
                    title: translate('adminPage.users.editUserConfirm'),
                    okType: 'danger',
                    okText: translate('buttons.yes'),
                    cancelText: translate('buttons.cancel'),
                    onOk: () => {
                        ajax()
                            .put(`/admin/users/${userEmail}`, values)
                            .then((response) => {
                                createNotificationShort(this.props.translate, {
                                    message: translate('messages.success.updateUserSuccess'),
                                    type: 'success',
                                });
                                this._isMounted && this.setState({ user: response.data, editedUser: response.data });
                                return response;
                            })
                            .catch((error) => {
                                createNotification(this.props.translate, error.response.data.code, {
                                    message: translate('messages.error.updateUserFailed'),
                                    type: 'error',
                                });
                                throw error;
                            })
                            .finally(() => {
                                this._isMounted &&
                                    this.setState({
                                        editModeEmail: false,
                                        editModeUserRole: false,
                                        editModeCredentialType: false,
                                        editModeOid: false,
                                        editModeLanguage: false,
                                        editModeTSA: false,
                                        editModePassword: false
                                    });
                            });
                    },
                    onCancel: () => {},
                });
            }
        });
    };

    handlePasswordChange = (e) => {
        this.props.form.validateFields(['password'], { force: true });
    };

    render() {
        const { history, translate, form } = this.props;
        const { credentialType } = this.state;
        const { Option } = Select;
        const showSetPassword = credentialType === 'pwd_hash' && authService.getPasswordEnabled();
        return (
            <WebPage location={history.location}>
                <Row type="flex" justify="center" align="middle">
                    <Col xs={24} md={20} lg={16}>
                        <Link to="/adminPage">
                            <Icon type="left-circle" style={{ fontSize: 20 }} />
                            <span style={{ fontSize: 20 }}> {translate('backToHomePage')}</span>
                        </Link>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <Col xs={24} md={20} lg={16}>
                        <Box>
                            <Form onSubmit={this.handleSubmit}>
                                <Descriptions title={translate('adminPage.users.userData')} bordered>
                                    <Descriptions.Item span={3} label={`${translate('adminPage.users.email')}:`}>
                                        {this.state.editModeEmail ? (
                                            <div>
                                                <FormItem>
                                                    {form.getFieldDecorator('email', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: translate('form.required'),
                                                            },
                                                            {
                                                                type: 'email',
                                                                message: translate('login.validateEmail'),
                                                            },
                                                        ],
                                                        initialValue: this.state.editedUser.email,
                                                        normalize: (input) =>
                                                            input !== undefined ? input.toLowerCase() : '',
                                                    })(<Input size="small" style={{ width: '70%', marginRight: 8 }} />)}
                                                    {this.saveCancelButtons('editModeEmail')}
                                                </FormItem>
                                            </div>
                                        ) : (
                                            <div>
                                                <span style={{ width: '60%', marginRight: 8 }}>
                                                    {this.state.editedUser.email}
                                                </span>
                                                {this.editButton('editModeEmail')}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={`${translate('adminPage.users.userType')}:`}>
                                        {this.state.editModeUserRole &&
                                        this.state.credentialType !== 'google_token' &&
                                        this.state.credentialType !== 'facebook_token' ? (
                                            <FormItem>
                                                {form.getFieldDecorator('role', {
                                                    initialValue: this.state.editedUser.role,
                                                })(
                                                    <Select
                                                        name={'role'}
                                                        disabled={this.state.user.role === 'owner'}
                                                        size={'small'}
                                                        style={{ width: '70%', marginRight: 8 }}
                                                    >
                                                        <Option value="owner" disabled={true}>
                                                            {translate('userRole.owner')}
                                                        </Option>
                                                        <Option value="admin">{translate('userRole.admin')}</Option>
                                                        <Option value="normal">{translate('userRole.normal')}</Option>
                                                    </Select>
                                                )}
                                                {this.saveCancelButtons('editModeUserRole')}
                                            </FormItem>
                                        ) : (
                                            <div>
                                                {translate(`userRole.${this.state.editedUser.role}`)}
                                                {this.editButton('editModeUserRole')}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        span={3}
                                        label={`${translate('adminPage.users.credentialType')}:`}
                                    >
                                        {this.state.editModeCredentialType &&
                                        this.state.credentialType !== 'google_token' &&
                                        this.state.credentialType !== 'facebook_token' ? (
                                            this.state.credentialType === 'ad_login' ? (
                                                <div>
                                                    <div>
                                                        <FormItem>
                                                            {form.getFieldDecorator('credentialType', {
                                                                initialValue: 'ad_login',
                                                            })(
                                                                <Select
                                                                    onChange={this.credentialChanged}
                                                                    size={'small'}
                                                                    style={{ width: '70%', marginRight: 8 }}
                                                                >
                                                                    <Option value="pwd_hash">
                                                                        {translate(`userCredential.pwd_hash`)}
                                                                    </Option>
                                                                    <Option value="ad_login">
                                                                        {translate(`userCredential.ad_login`)}
                                                                    </Option>
                                                                </Select>
                                                            )}
                                                            {this.saveCancelButtons('editModeCredentialType')}
                                                        </FormItem>
                                                        <FormItem>
                                                            {form.getFieldDecorator('adIdentifier', {
                                                                rules: [
                                                                    {
                                                                        required:
                                                                            this.state.user.credentialType ===
                                                                            'pwd_hash',
                                                                    },
                                                                ],
                                                            })(
                                                                <Input
                                                                    size="small"
                                                                    style={{ width: '70%', marginRight: 8 }}
                                                                    prefix={<Icon type="user" />}
                                                                    placeholder={translate(
                                                                        'adminPage.users.adIdentifier'
                                                                    )}
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </div>
                                            ) : (
                                                <FormItem>
                                                    {form.getFieldDecorator('credentialType', {
                                                        initialValue: this.state.editedUser.credentialType,
                                                    })(
                                                        <Select
                                                            onChange={this.credentialChanged}
                                                            size={'small'}
                                                            style={{ width: '70%', marginRight: 8 }}
                                                        >
                                                            <Option value="pwd_hash">
                                                                {translate('login.passwordInput')}
                                                            </Option>
                                                            <Option value="ad_login">
                                                                {translate(`userCredential.ad_login`)}
                                                            </Option>
                                                        </Select>
                                                    )}
                                                    {this.saveCancelButtons('editModeCredentialType')}
                                                </FormItem>
                                            )
                                        ) : (
                                            <div>
                                                {translate(`userCredential.${this.state.editedUser.credentialType}`)}
                                                {this.editButton('editModeCredentialType')}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                    {showSetPassword && (
                                        <Descriptions.Item
                                            span={3}
                                            label={`${translate('adminPage.users.setPassword')}:`}
                                        >
                                            {this.state.editModePassword ? (
                                                <div>
                                                    <FormItem>
                                                        {form.getFieldDecorator('password', {})(
                                                            <Input.Password
                                                                style={{ width: '70%', marginRight: 8 }}
                                                                size="small"
                                                                prefix={<Icon type="lock" />}
                                                                placeholder={translate('adminPage.users.setPassword')}
                                                                type={'password'}
                                                                onChange={this.handlePasswordChange}
                                                            />
                                                        )}
                                                        {this.saveCancelButtons('editModePassword')}
                                                    </FormItem>
                                                </div>
                                            ) : (
                                                <div>
                                                    {this.state.editedUser.password}
                                                    {this.editButton('editModePassword')}
                                                </div>
                                            )}
                                        </Descriptions.Item>
                                    )}
                                    <Descriptions.Item span={3} label={`${translate('adminPage.users.oid')}:`}>
                                        {this.state.editModeOid ? (
                                            <div>
                                                <FormItem>
                                                    {form.getFieldDecorator('oid', {
                                                        initialValue: this.state.editedUser.oid,
                                                    })(
                                                        <Input
                                                            style={{ width: '70%', marginRight: 8 }}
                                                            size="small"
                                                            autoComplete={'new-password'}
                                                            prefix={<Icon type="user" />}
                                                            placeholder={translate(
                                                                'modals.tsa.fields.tsaUser.placeholder'
                                                            )}
                                                            type={'text'}
                                                        />
                                                    )}
                                                    {this.saveCancelButtons('editModeOid')}
                                                </FormItem>
                                                <FormItem>
                                                    {form.getFieldDecorator(
                                                        'oidPassword',
                                                        {}
                                                    )(
                                                        <Input.Password
                                                            style={{ width: '70%', marginRight: 8 }}
                                                            size="small"
                                                            autoComplete={'new-password'}
                                                            prefix={<Icon type="lock" />}
                                                            placeholder={translate(
                                                                'modals.tsa.fields.tsaPassword.placeholder'
                                                            )}
                                                        />
                                                    )}
                                                </FormItem>
                                            </div>
                                        ) : (
                                            <div>
                                                {this.state.editedUser.oid}
                                                {this.editButton('editModeOid')}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={`${translate('adminPage.users.created')}:`}>
                                        {formatDate(this.state.editedUser.created)}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={`${translate('adminPage.users.language')}:`}>
                                        {this.state.editModeLanguage ? (
                                            <FormItem>
                                                {form.getFieldDecorator('language', {
                                                    initialValue: this.state.editedUser.language,
                                                })(
                                                    <Select size={'small'} style={{ width: '70%', marginRight: 8 }}>
                                                        <Option value="hu">{translate('languages.hu')}</Option>
                                                        <Option value="en">{translate('languages.en')}</Option>
                                                        <Option value="pl">{translate('languages.pl')}</Option>
                                                    </Select>
                                                )}
                                                {this.saveCancelButtons('editModeLanguage')}
                                            </FormItem>
                                        ) : (
                                            <div>
                                                {translate(`languages.${this.state.editedUser.language}`)}
                                                {this.editButton('editModeLanguage')}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={`${translate('adminPage.users.profile')}:`}>
                                        {this.state.editedUser.profile !== undefined
                                            ? translate('user.profile.' + this.state.editedUser.profile)
                                            : ''}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={`${translate('adminPage.users.tsUser')}:`}>
                                        {this.state.editModeTSA ? (
                                            <div>
                                                <FormItem>
                                                    {form.getFieldDecorator('tsUser', {
                                                        initialValue: this.state.editedUser.tsUser,
                                                    })(
                                                        <Input
                                                            style={{ width: '70%', marginRight: 8 }}
                                                            size="small"
                                                            prefix={<Icon type="user" />}
                                                            placeholder={translate(
                                                                'modals.tsa.fields.tsaUser.placeholder'
                                                            )}
                                                        />
                                                    )}
                                                    {this.saveCancelButtons('editModeTSA')}
                                                </FormItem>
                                                <FormItem>
                                                    {form.getFieldDecorator(
                                                        'tsPassword',
                                                        {}
                                                    )(
                                                        <Input
                                                            style={{ width: '70%', marginRight: 8 }}
                                                            size="small"
                                                            prefix={<Icon type="lock" />}
                                                            placeholder={translate(
                                                                'modals.tsa.fields.tsaPassword.placeholder'
                                                            )}
                                                            type={'password'}
                                                        />
                                                    )}
                                                </FormItem>
                                            </div>
                                        ) : (
                                            <div>
                                                {this.state.editedUser.tsUser}
                                                {this.editButton('editModeTSA')}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Form>
                        </Box>
                    </Col>
                </Row>
            </WebPage>
        );
    }
}

EditUser.propTypes = {
    form: PropTypes.object.isRequired,
    getUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUser: (email) => {
            return dispatch(getUser(email));
        },
    };
}
const WrappedEditUser = Form.create()(EditUser);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedEditUser);
