/* eslint-disable react-hooks/exhaustive-deps */
import WebPage from './WebPage';
import {Button, Col, Dropdown, Icon, List, Menu, Row, Steps} from 'antd';
import {Link} from 'react-router-dom';
import Box from './Box';
import CustomAlert from './CustomAlert';
import Dropzone from 'react-dropzone';
import {
    acceptedExtensions,
    ajax,
    countPadding,
    createNotification,
    downloadContainer,
    downloadContainersInZip,
    uriTypeFromExtension,
    validateFileSize
} from '../helper';
import theme from '../theme';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import {fetchAllSignatureProfiles} from '../actions/signatureProfile';
import {removeModal, toggleModal} from '../actions/modal';
import {ATTRIBUTE_ROLES, SELECT_CERTIFICATE, SIGN, USER_PROFILE_ERROR} from '../constants/modalNames';
import PropTypes from 'prop-types';
import {deleteMultiple} from './Sign';
import {getAttributeUsage} from '../actions/user';

export default function BulkSigning(props) {
    const { history } = props;
    const dispatch = useDispatch();

    const [containers, setContainers] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [error, setError] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [signModalData, setSignModalData] = useState([]);
    const [status, setStatus] = useState('process');
    const [uploadFinished, setUploadFinished] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));
    const signatureProfiles = useSelector((state) => state.signatureProfiles);
    const defaultSignatureProfile = useSelector((state) => state.signatureProfiles.find((profile) => profile.isDefault));

    useEffect(() => {
        setIsMounted(true);
        fetchSignatureProfiles().catch((e) => {
            createNotification(translate, e.response.data.code, {
                message: translate('notifications.getData.failure'),
                type: 'error'
            });
        });

        return () => {
            setIsMounted(false);
            let ackContainerIds = getAckContainerIds();
            if (ackContainerIds.length > 0) {
                deleteMultiple(ackContainerIds);
            }
            let containerIds = getContainerIds();
            if (containerIds.length > 0) {
                deleteMultiple(containerIds);
            }
        };
    }, []);

    useEffect(() => {
        if (currentStep === 1) {
            start('SIGNATURE');
        }
    }, [currentStep]);

    function fetchSignatureProfiles() {
        return dispatch(fetchAllSignatureProfiles());
    }

    function onDrop(acceptedFiles) {
        let newContainers = containers.slice();
        for (let i = 0; i < acceptedFiles.length; i++) {
            try {
                let file = acceptedFiles[i];
                validateFileSize(file);
                newContainers.push({ file: file, id: null });
            } catch (error) {
                if (error.message === 'USER_MAX_UPLOAD_SIZE_ERROR') {
                    createNotification(translate, error.message, {
                        description: translate(`errorCodes.${error.message}`),
                        message: translate('notifications.uploadFailure.message'),
                        type: 'error'
                    });
                }
            }
        }
        isMounted && setContainers(newContainers);
    }

    function uploadFiles() {
        let toUploadIndex = getToUploadIndex();
        if (toUploadIndex < 0) {
            // nothing to upload
            if (isMounted) {
                setCurrentStep(1);
                setUploadFinished(true);
            }
            return;
        }
        let file = containers[toUploadIndex].file;
        let type = uriTypeFromExtension(file.name.split('.').pop().toLowerCase());

        if (!type) {
            createNotification(translate, undefined, {
                description: translate('notifications.uploadUnknownTypeFailure.description', {
                    fileName: file.name
                }),
                message: translate('notifications.uploadUnknownTypeFailure.message'),
                type: 'error'
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('hidden', 'true');
        ajax()
            .post(`/containers/upload`, formData)
            .then(async (response) => {
                let newContainers = containers;
                newContainers[toUploadIndex].id = response.data.id;
                let pdfSettings = null;
                if (response.data.mimeType === 'application/pdf') {
                    const pdfImgRes = await ajax().get(
                        `${window.config.REACT_APP_SERVICE_BASE_URL}/containers/${
                            response.data.id
                        }/preview?pageNumber=${1}`
                    );
                    const pdfSizeX = pdfImgRes.data.imageWidth;
                    const pdfSizeY = pdfImgRes.data.imageHeight;
                    const signProfile = defaultSignatureProfile;
                    const imgResize = Math.floor((signProfile.width / pdfSizeX) * 100);
                    pdfSettings = {
                        signatureProfileId: signProfile.id,
                        pdfImagePaddingX: countPadding(signProfile.x, pdfSizeX),
                        pdfImagePaddingY: countPadding(signProfile.y, pdfSizeY),
                        pdfImagePage: 1,
                        pdfImageResize: imgResize,
                        pdfReason: ''
                    };
                }
                const modalData = { containerId: response.data.id, pdfSettings };
                if (isMounted) {
                    setContainers(newContainers);
                    setSignModalData(prevState => [...prevState, modalData]);
                }
                uploadFiles();
            })
            .catch((error) => {
                let newContainers = containers;
                newContainers[toUploadIndex].id = '';
                if (error.response) {
                    if (isMounted) {
                        setContainers(newContainers);
                        setError(error.response.data.error) && setStatus('error');
                    }
                } else {
                    if (isMounted) {
                        setContainers(newContainers);
                        setError({ code: 'HTTP_NO_RESPONSE' }) && setStatus('error');
                    }

                }
            });
    }

    function onUploadFiles() {
        const maxContainerSignNumber = parseInt(localStorage.getItem('massDocumentSignMaxNumber'));
        const canSignPDF = localStorage.getItem('signPDF') === 'true';
        const canSignContainer = localStorage.getItem('signContainers') === 'true';
        const forbidContainerSignature =
            containers.filter(
                (container) =>
                    container.file.type === 'application/vnd.etsi.asic-e+zip' ||
                    container.file.type === 'application/vnd.eszigno3+xml'
            ).length > 0 && !canSignContainer;
        const forbidPDFSignature =
            containers.filter((container) => container.file.type === 'application/pdf').length > 0 &&
            !canSignPDF;
        const tooManyFiles = containers.length > maxContainerSignNumber;
        const locale = [];
        if (forbidPDFSignature) {
            locale.push(translate('user.profile.error.cantSignPDF'));
        }
        if (forbidContainerSignature) {
            locale.push(translate('user.profile.error.cantSignContainer'));
        }
        if (tooManyFiles) {
            locale.push(
                translate('modals.massSigning.maxContainerSignNumberText') + ' ' + maxContainerSignNumber
            );
        }
        if (forbidContainerSignature || forbidPDFSignature || tooManyFiles) {
            dispatch(toggleModal(USER_PROFILE_ERROR, { locale: locale }));
            return;
        }
        if (isMounted) {
            setUploadStarted(true);
        }
        uploadFiles();
    }

    function onDownloadFiles() {
        const containerIds = [...getContainerIds()];
        downloadContainersInZip(containerIds);
    }

    function changeSignatureProfile(selectedProfile) {
        const id = selectedProfile.key;
        ajax()
            .put(`/signatureProfiles/${id}`, { isDefault: true })
            .then(() => {
                fetchSignatureProfiles();
            })
            .catch((e) => {
                console.error(e);
            });
    }

    function start(signingType) {
        dispatch(getAttributeUsage()).then((response) => {
            if (response.data.attributeCertUsage) {
                ajax()
                    .get('/user/certificate')
                    .then(() => {
                        dispatch(toggleModal(ATTRIBUTE_ROLES, {
                            startSign: (selectedRole) => startSign(signingType, selectedRole, null)
                        }));
                    })
                    .catch(() => {
                        dispatch(toggleModal(SELECT_CERTIFICATE, {
                            startSign: (selectedRole, certificate) => {
                                startSign(signingType, selectedRole, certificate);
                            }
                        }));
                    });
            } else {
                startSign(signingType, null);
            }
        });
    }

    function startSign(signingType, selectedRole, certificate) {
        dispatch(toggleModal(SIGN, {
            signModalData: signModalData,
            signingType: signingType,
            attributeRole: selectedRole,
            certificate: certificate,
            onFinished: (result) => {
                if (result.succeeded) {
                    setTimeout(() => {
                        dispatch(removeModal());
                    }, 1000);
                    if (isMounted) {
                        setContainers(containers);
                        setCurrentStep(2);
                    }
                } else {
                    isMounted && setStatus('error');
                }
            }
        }));
    }

    const getToUploadIndex = () => {
        for (let i = 0; i < containers.length; i++) {
            if (containers[i].id === null) {
                return i;
            }
        }
        return -1;
    };

    const getContainerIds = () => {
        let containerIds = [];
        for (let i = 0; i < containers.length; i++) {
            containerIds.push(containers[i].id);
        }
        return containerIds;
    };

    const getAckContainerIds = () => {
        let ackContainerIds = [];
        for (let i = 0; i < containers.length; i++) {
            const containerAckId = containers[i].ackId;
            if (containerAckId !== undefined) {
                ackContainerIds.push(containerAckId);
            }
        }
        return ackContainerIds;
    };

    const getSelectedProfileName = () => {
        return defaultSignatureProfile === undefined
            ? ''
            : handleProfileNaming(defaultSignatureProfile);
    };

    const handleProfileNaming = (profile) => {
        let name;
        profile.profileName === 'default'
            ? name = translate('defaultSchema')
            : profile.profileName === 'empty'
            ? name = translate('invisible.sign')
            : name = profile.profileName;
        return name;
    };

    const menuItems = signatureProfiles.map((profile) => {
        return (
            <Menu.Item key={profile.id}>
                {handleProfileNaming(profile)}
            </Menu.Item>
        );
    });

    const menu = <Menu onClick={changeSignatureProfile}>{menuItems}</Menu>;

    return (
        <WebPage location={history.location}>
            <Row type='flex' justify='center' align='middle'>
                <Col xs={24} md={20} lg={16} xl={14}>
                    <Link to='/'>
                        <Icon type='left-circle' style={{ fontSize: 20 }} />
                        <span style={{ fontSize: 20 }}> {translate('backToHomePage')}</span>
                    </Link>
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col xs={24} md={20} lg={16} xl={14}>
                    <Row style={{ marginTop: 30 }} type='flex' align='middle' justify='space-between'>
                        <Col align='left' justify='left'>
                            <h3>{translate('header.links.tools.massSign')}</h3>
                        </Col>
                        <Col align='right' justify='right'>
                            <Row type='flex' align='middle' justify='space-between'>
                                <Col align='left' justify='left'>
                                    <h3>{translate('modals.massSigning.signatureProfileInfo')}</h3>
                                </Col>
                                <Col align='right' justify='right' style={{ paddingBottom: 8 }}>
                                    <Dropdown overlay={menu} trigger={['hover']}>
                                        <Button
                                            style={{
                                                border: 'none',
                                                color: 'rgba(0, 0, 0)',
                                                fontSize: 16
                                            }}
                                            size={'small'}
                                            type={'default'}
                                        >
                                            {getSelectedProfileName()}
                                            <Icon type='down' style={{ marginRight: 0 }} />
                                        </Button>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Box>
                        <Steps current={currentStep} status={status}>
                            <Steps.Step title={translate('modals.massSigning.step0.title')} />
                            <Steps.Step title={translate('modals.massSigning.step1.title')} />
                            <Steps.Step title={translate('modals.massSigning.step2.title')} />
                        </Steps>

                        {error && <CustomAlert error={error} />}

                        <div style={{ margin: '1em' }}>
                            {currentStep === 0 && !uploadStarted && (
                                <div>
                                    <Dropzone accept={acceptedExtensions().join(',')} onDrop={onDrop}>
                                        {({ getRootProps, getInputProps }) => {
                                            return (
                                                <section
                                                    style={{
                                                        ...theme.dropZoneStyle,
                                                        marginTop: 14,
                                                        minHeight: 150
                                                    }}>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <div style={theme.dropZoneOverlayBoxStyle}>
                                                            <div>
                                                                <Icon
                                                                    type='cloud-upload'
                                                                    theme='outlined'
                                                                    style={{ fontSize: '32px', marginRight: 10 }}
                                                                />
                                                            </div>
                                                            {translate(
                                                                'modals.massSigning.dropzoneUploadFiles'
                                                            )}
                                                        </div>
                                                    </div>
                                                </section>
                                            );
                                        }}
                                    </Dropzone>
                                </div>
                            )}
                            {(currentStep === 0 || currentStep === 2) && (
                                <div style={{ height: 200, overflow: 'auto' }}>
                                    {containers.map((container, i) => {
                                        return (
                                            <Row key={i}>
                                                <List.Item>
                                                    <List.Item.Meta
                                                        description={
                                                            <span style={{ marginRight: 10 }}>
                                                                    {container.file.name}
                                                                </span>
                                                        }
                                                    />
                                                    <div>
                                                        {currentStep === 0 &&
                                                        translate(
                                                            container.id === null
                                                                ? 'modals.massSigning.toBeUploaded'
                                                                : container.id === ''
                                                                ? 'modals.massSigning.failedToUpload'
                                                                : 'modals.massSigning.uploaded'
                                                        )}
                                                        {currentStep === 2 && (
                                                            <Button
                                                                onClick={() =>
                                                                    downloadContainer(
                                                                        container.id
                                                                    )}
                                                                style={{
                                                                    border: 'none',
                                                                    color: '#ef790c',
                                                                    height: 'auto',
                                                                    lineHeight: '21px',
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    transitionProperty: 'color',
                                                                    transitionTimingFunction: 'ease'
                                                                }}>
                                                                {translate('modals.massSigning.linkDownload')}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </List.Item>
                                            </Row>
                                        );
                                    })}
                                </div>
                            )}

                            {currentStep === 0 && !uploadStarted && !uploadFinished && (
                                <Button
                                    block
                                    type='primary'
                                    disabled={containers.length < 1}
                                    onClick={onUploadFiles}
                                >
                                    {translate('modals.tsa.buttonNext.text')}
                                </Button>
                            )}

                            {currentStep === 2 && (
                                <Button
                                    block
                                    style={{ marginBottom: '1em' }}
                                    type='primary'
                                    onClick={onDownloadFiles}
                                >
                                    {translate('modals.massSigning.buttonDownloadFiles')}
                                </Button>
                            )}

                            {currentStep > 0 && (
                                <Button
                                    block
                                    type='primary'
                                    onClick={() => {
                                        if (isMounted) {
                                            setContainers([]);
                                            setSignModalData([]);
                                            setError(null);
                                            setCurrentStep(0);
                                            setStatus('process');
                                            setUploadStarted(false);
                                            setUploadFinished(false);
                                        }
                                    }}>
                                    {translate('modals.massSigning.restart')}
                                </Button>
                            )}
                        </div>
                    </Box>
                </Col>
            </Row>
        </WebPage>
    );
}

BulkSigning.propTypes = {
    history: PropTypes.object.isRequired
};
