import { Button, Form, Input, Skeleton } from 'antd';
import { addSignatureProfile, toggleDefaultSignatureProfile } from '../../actions/signatureProfile';
import PropTypes from 'prop-types';
import React from 'react';
import SignImage from './SignImage';
import TitleOptionsDropdown from './TitleOptionsDropdown';
import {
    ajax,
    createNotification,
    createNotificationShort,
    validateFileSize,
    validateFileSignature,
} from '../../helper';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { removeModal } from '../../actions/modal';
import { addSignatureProfileImage } from '../../actions/signatureProfileImage';
import UserProfileError from '../UserProfileError';

class CreateSignatureProfile extends React.Component {
    constructor(props) {
        super(props);
        this._customImageUploadEnabled = localStorage.getItem('customSignatureImage') === 'true';
        this._visibleSignatureConstraints = props.modalData.visibleSignatureConstraints;
        this.state = {
            pageNumber: 1,
            loading: true,
            data: '',
            deltaPosition: {
                x: 0,
                y: 0,
            },
            error: null,
            width: this._visibleSignatureConstraints.defaultWidth,
            height: this._visibleSignatureConstraints.defaultHeight,
            backgroundImage: "url('/img/sigdef_172.png')",
            minConstraints: [this._visibleSignatureConstraints.minWidth, this._visibleSignatureConstraints.minHeight],
            maxConstraints: [this._visibleSignatureConstraints.maxWidth, this._visibleSignatureConstraints.maxHeight],
            file: null,
            schemaName: '',
            allowedExtensions: ['png'],
            isImageUploaded: false,
            hasPdfSignTitle: true,
            hasPdfSignTitleDate: false,
            imgDetails: {
                width: 860,
                height: 172,
            },
            isTitleModificationDisabled: !this._customImageUploadEnabled,
        };

        this._isMounted = false;
        ajax()
            .get(`/signatureImage/1`)
            .then((response) => {
                this._isMounted && this.setState(
                    {
                        backgroundImage: `data:image/png;base64,${response.data.image}`,
                    });
            })
            .catch((e) => {
                console.error(e);
            });
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDragStop = (e, d) => {
        this._isMounted && this.setState({ deltaPosition: { x: d.x, y: d.y } });
    };

    onResize = (e, dir, ref) => {
        this._isMounted &&
            this.setState({
                width: +ref.style.width.replace('px', ''),
                height: +ref.style.height.replace('px', ''),
                resize: Math.ceil((this.state.width / this.props.pdfSizeX) * 100),
            });
    };

    saveSchema = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err) => {
            if (!err) {
                let requestBody = {
                    name: this.state.schemaName,
                    width: Math.round(this.state.width),
                    height: Math.round(this.state.height),
                    x: this.state.deltaPosition.x,
                    y: this.state.deltaPosition.y,
                    isDefault: true,
                    hasPdfSignTitle: this.state.isTitleModificationDisabled ? true : this.state.hasPdfSignTitle,
                    hasPdfSignTitleDate: this.state.hasPdfSignTitleDate,
                };

                if (this.state.isImageUploaded) {
                    const base64EncodedImage = await new Promise((resolve, reject) => {
                        var reader = new FileReader();
                        reader.readAsDataURL(this.state.file);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = (error) => reject(error);
                    });
                    // MAGIC NUMBER: remove data:image/png;base64, from the string
                    requestBody['signImage'] = await base64EncodedImage.substring(22);
                }

                ajax()
                    .post('/signatureProfiles', requestBody)
                    .then((response) => {
                        const newProfile = response.data[response.data.length - 1];
                        createNotificationShort(this.props.translate, {
                            description: `${newProfile.profileName} ${this.props.translate(
                                'schema.successfully.saved'
                            )}`,
                            message: this.props.translate('notifications.uploadOK.message'),
                            type: 'success',
                        });
                        this.props.addSignatureProfile(newProfile);
                        this.props.toggleDefaultSignatureProfile(newProfile.id);
                        this.props.addSignatureProfileImage({ id: newProfile.id, image: requestBody.signImage });
                        return response;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                this.props.hideModal();
                this._isMounted && this.setState({ isImageUploaded: false });
            }
        });
    };

    eventPath = (evt) => {
        var path = (evt.composedPath && evt.composedPath()) || evt.path,
            target = evt.target;

        if (path != null) {
            // Safari doesn't include Window, but it should.
            return path.indexOf(window) < 0 ? path.concat(window) : path;
        }

        if (target === window) {
            return [window];
        }

        function getParents(node, memo) {
            memo = memo || [];
            var parentNode = node.parentNode;

            if (!parentNode) {
                return memo;
            } else {
                return getParents(parentNode, memo.concat(parentNode));
            }
        }

        return [target].concat(getParents(target), window);
    };

    loadImage = (file) => {
        let fileExtensionLowerCase = file.name.split('.').pop().toLowerCase();
        if (this.state.allowedExtensions.includes(fileExtensionLowerCase)) {
            const src = URL.createObjectURL(file);
            const img = new Image();
            img.onload = (e) => {
                if (img.height < 60) {
                    createNotificationShort(this.props.translate, {
                        description: this.props.translate('accepted.imageSize'),
                        message: this.props.translate('badFileSize'),
                        type: 'error'
                    })
                    return;
                }
                const loadedWidth = this._visibleSignatureConstraints.minWidth * 2;
                const loadedHeight = Math.round(img.height * (loadedWidth / img.width));
                const minImgWidth = this._visibleSignatureConstraints.minWidth;
                const maxImgWidth = this._visibleSignatureConstraints.maxWidth;
                this._isMounted &&
                    this.setState({
                        width: loadedWidth,
                        height: loadedHeight,
                        file: file,
                        isImageUploaded: true,
                        minConstraints: [minImgWidth, loadedHeight * (minImgWidth / loadedWidth)],
                        maxConstraints: [maxImgWidth, loadedHeight * (maxImgWidth / loadedWidth)],
                        backgroundImage: `${src}`,
                        imgDetails: {
                            width: this.eventPath(e)[0].width,
                            height: this.eventPath(e)[0].height,
                        },
                    });
            };
            img.src = src;
        } else {
            createNotificationShort(this.props.translate, {
                description: this.props.translate('accepted.imageFormats'),
                message: this.props.translate('badFileFormat'),
                type: 'error',
            });
        }
    };

    handleImageUpload = async (event) => {
        const file = event.target.files[0];
        this.imageUpload.value = null;
        try {
            validateFileSize(file);
            await validateFileSignature(file, 'png').then((result) => {
                if (result.isError === true) {
                    createNotificationShort(this.props.translate, {
                        description: this.props.translate('accepted.imageFormats'),
                        message: this.props.translate('badFileFormat'),
                        type: 'error',
                    });
                }
            });
        } catch (error) {
            // I don't know why it works like this, but at this point I will just accept it
            if (error.isError === false) {
                this.loadImage(file);
            }
            if (error.message === 'USER_MAX_UPLOAD_SIZE_ERROR') {
                createNotification(this.props.translate, error.message, {
                    description: this.props.translate(`errorCodes.${error.message}`),
                    message: this.props.translate('notifications.uploadFailure.message'),
                    type: 'error',
                });
            }
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            schemaName,
            width,
            height,
            deltaPosition,
            minConstraints,
            maxConstraints,
            backgroundImage,
            imgDetails,
        } = this.state;
        const { translate } = this.props;
        const visibleSignatureImageNumberLimit = this._visibleSignatureConstraints.numberLimit;
        const visibleSignatureTextProps = this._visibleSignatureConstraints.signatureTextProps
        const bronzeLimitReached = this.props.signatureProfiles.length >= visibleSignatureImageNumberLimit && !this._customImageUploadEnabled;
        return (
            <div>
                {bronzeLimitReached && (
                    <div style={{ paddingTop: '8px' }}>
                        <UserProfileError size={64} icon='user' />
                    </div>
                )}
                <nav
                    style={{
                        textAlign: 'center',
                        padding: 16,
                        marginBottom: 16,
                        marginTop: 26,
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #e8e8e8',
                    }}
                >
                    <Form onSubmit={this.saveSchema}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                <Button
                                    id={'imageUpload'}
                                    type="primary"
                                    onClick={() => this.imageUpload.click()}
                                    disabled={!this._customImageUploadEnabled   }
                                >
                                    {translate('image.upload')}
                                    <input
                                        ref={(ref) => (this.imageUpload = ref)}
                                        id="imageUpload"
                                        onChange={this.handleImageUpload}
                                        type="file"
                                        accept="image/png,.png"
                                        multiple
                                        style={{ display: 'none' }}
                                    />
                                </Button>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <TitleOptionsDropdown
                                        changeDate={(e) =>
                                            this._isMounted && this.setState({ hasPdfSignTitleDate: e.target.checked })
                                        }
                                        changeTitle={(e) =>
                                            this._isMounted && this.setState({ hasPdfSignTitle: e.target.checked })
                                        }
                                        hasDate={this.state.hasPdfSignTitleDate}
                                        hasTitle={this.state.hasPdfSignTitle}
                                        isTitleModificationDisabled={this.state.isTitleModificationDisabled}
                                        translate={translate}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                {getFieldDecorator('schemaname', {
                                    initialValue: schemaName,
                                    rules: [{ required: true, message: translate('fill.empty.field') }],
                                })(
                                    <Input
                                        style={{ width: '250px' }}
                                        placeholder={translate('schemaname')}
                                        onChange={(e) =>
                                            this._isMounted && this.setState({ schemaName: e.target.value })
                                        }
                                    />
                                )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: '0px' }}>
                                <Button disabled={bronzeLimitReached} id={'imageUploadDone'} type={'primary'} htmlType="submit">
                                    {translate('button.done')}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </nav>
                <div
                    className="signature-profile-blank-page"
                    style={{
                        width: this.props.pdfSizeX,
                        height: this.props.pdfSizeY,
                    }}
                >
                    <SignImage
                        props={{
                            width: width,
                            height: height,
                            deltaPosition: deltaPosition,
                            pdfSizeX: this.props.pdfSizeX,
                            minConstraints: minConstraints,
                            maxConstraints: maxConstraints,
                            translate: translate,
                            onDragStop: this.onDragStop,
                            onResize: this.onResize,
                            namePosition: this.state.hasPdfSignTitle,
                            datePosition: this.state.hasPdfSignTitleDate,
                            fontSize: Math.floor((width / imgDetails.width) * visibleSignatureTextProps.fontSizeMultiplier),
                            isResizingAndDraggingDisabled: this.state.isTitleModificationDisabled,
                            signatureTextProps: visibleSignatureTextProps
                        }}
                        backgroundImage={backgroundImage}
                    />
                    <Skeleton title paragraph={true} />
                    <Skeleton paragraph={{ rows: 5 }} />
                    <Skeleton paragraph={true} />
                    <Skeleton paragraph={true} />
                </div>
            </div>
        );
    }
}

CreateSignatureProfile.propTypes = {
    pdfSizeX: PropTypes.number,
    pdfSizeY: PropTypes.number,
    validateFields: PropTypes.func,
    translate: PropTypes.func,
    onClose: PropTypes.func,
    form: PropTypes.object,
    hideModal: PropTypes.func,
    addSignatureProfile: PropTypes.func,
    toggleDefaultSignatureProfile: PropTypes.func,
};

CreateSignatureProfile.defaultProps = {
    pdfSizeX: 595,
    pdfSizeY: 842,
};

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.locale),
        signatureProfiles: state.signatureProfiles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => dispatch(removeModal()),
        addSignatureProfile: (profile) => dispatch(addSignatureProfile(profile)),
        toggleDefaultSignatureProfile: (index) => dispatch(toggleDefaultSignatureProfile(index)),
        addSignatureProfileImage:(profile) => dispatch(addSignatureProfileImage(profile))
    };
};

const CreateSignatureSchemaForm = Form.create({ name: 'create_signature_schema' })(CreateSignatureProfile);

export default connect(mapStateToProps, mapDispatchToProps)(CreateSignatureSchemaForm);
